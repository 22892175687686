import React from 'react';
import { PricesContainer, PricesWrapper, PricesTable } from './PricesElements';
import generals from '../Data/generalsPriceTable';

const Prices = () => {
    return (
        <PricesContainer id="prices">
            <PricesWrapper>
                {
                    generals.map((general) => {
                        return <PricesTable>
                            <ul>
                                <li class="generalTitle" key={general.name}>{general.name}</li>
                                <li style={ general.row1[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row1[0]}</li>
                                <li style={ general.row2[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row2[0]}</li>   
                                <li style={ general.row3[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row3[0]}</li>
                                <li style={ general.row4[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row4[0]}</li>
                                <li style={ general.row5[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row5[0]}</li>
                                <li style={ general.row6[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row6[0]}</li>
                                <li style={ general.row7[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row7[0]}</li>
                                <li style={ general.row8[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row8[0]}</li>
                                <li style={ general.row9[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row9[0]}</li>
                                <li style={ general.row10[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row10[0]}</li>
                                <li style={ general.row11[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row11[0]}</li>
                                <li style={ general.row12[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row12[0]}</li>
                                <li style={ general.row13[1] === true ? {color: 'white'} : {color: '#80808061'} }>{general.row13[0]}</li>
                                <li class="generalPrice" key={general.price}>Ár: {general.price}</li>
                            </ul>
                        </PricesTable>
                    })
                }
            </PricesWrapper>
        </PricesContainer>
    )
}

export default Prices;
