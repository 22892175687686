const generals = [
    {
        name: 'Kis Generál',
        row1: ['Általános kerékpár átvizsgálás', true],
        row2: ['Műszaki tisztítás', false],
        row3: ['Fékrendszer átvizsgálás beállítás', true],
        row4: ['Váltórendszer átvizsgálás beállítás', true],
        row5: ['Lánctisztítás és kenés', true],
        row6: ['Kerék Centrírozás', true],
        row7: ['Kerék csapágy beállítás', true],
        row8: ['Középcsapágy zsírzás', false],
        row9: ['Kormánycsapágy zsírzás', false],
        row10: ['Agyak átnézése zsírzása', false],
        row11: ['Teleszkóp szárak kitisztítása', false],
        row12: ['Teleszkóp kenőanyag feltöltés', false],
        row13: ['Teleszkóp beállítás', false],
        price: '8000Ft',
    },
    {
        name: 'Nagy Generál',
        row1: ['Általános kerékpár átvizsgálás', true],
        row2: ['Műszaki tisztítás', true],
        row3: ['Fékrendszer átvizsgálás beállítás', true],
        row4: ['Váltórendszer átvizsgálás beállítás', true],
        row5: ['Lánctisztítás és kenés', true],
        row6: ['Kerék Centrírozás', true],
        row7: ['Kerék csapágy beállítás', true],
        row8: ['Középcsapágy zsírzás', true],
        row9: ['Kormánycsapágy zsírzás', true],
        row10: ['Agyak átnézése zsírzása', true],
        row11: ['Teleszkóp szárak kitisztítása', false],
        row12: ['Teleszkóp kenőanyag feltöltés', false],
        row13: ['Teleszkóp beállítás', false],
        price: '16000Ft'
    }, 
    {
        name: 'Full Szerviz',
        row1: ['Általános kerékpár átvizsgálás', true],
        row2: ['Műszaki tisztítás', true],
        row3: ['Fékrendszer átvizsgálás beállítás', true],
        row4: ['Váltórendszer átvizsgálás beállítás', true],
        row5: ['Lánctisztítás és kenés', true],
        row6: ['Kerék Centrírozás', true],
        row7: ['Kerék csapágy beállítás', true],
        row8: ['Középcsapágy zsírzás', true],
        row9: ['Kormánycsapágy zsírzás', true],
        row10: ['Agyak átnézése zsírzása', true],
        row11: ['Teleszkóp szárak kitisztítása', true],
        row12: ['Teleszkóp kenőanyag feltöltés', true],
        row13: ['Teleszkóp beállítás', true],
        price: '22000Ft'
    }
]

export default generals;