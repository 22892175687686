export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Kerékpárszerviz Dunakeszin',
    headLine: 'Elindítottuk kerékpárszervizünket, várunk mindenkt nagy szeretettel!',
    description: 'Tudj meg többet szolgáltatásainkról:',
    buttonLabel: 'Tovább',
    imgStart: true,
    img: require('../../images/svg-2.svg').default,
    alt: 'Bike',
    dark: true,
    primary: true,
    darkText: false,
}

export const homeObjTwo = {
    id: 'price',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Szöveg szöveg',
    headLine: 'Valami hosszab szöveg Valami hosszab szöveg',
    description: 'valami szöveg',
    buttonLabel: 'valami szöveg',
    imgStart: false,
    img: require('../../images/svg-1.svg').default,
    alt: 'piiggy',
    dark: false,
    primary: false,
    darkText: true,
}

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Kerékpárszerviz Dunakeszin',
    headLine: 'Elindítottuk kerékpárszervizünket, várunk mindenkt nagy szeretettel!',
    description: 'Tudj meg többet szolgáltatásainkról...',
    buttonLabel: 'Tovább',
    imgStart: false,
    img: require('../../images/svg-4.svg').default,
    alt: 'Bike',
    dark: false,
    primary: false,
    darkText: true,
}