import styled from "styled-components";

export const PricesContainer = styled.div`
    color: black;
    background: #fff;
    display: grid;
    margin: 5rem 0;
    min-height: 100vh;
    @media screen and (max-width: 768px){
        padding: 100px 0;
    }
`;

export const PricesWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    z-index: 1;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    display: grid;
    grid-gap: 16px;
    cursor: pointer;

    .generalTitle{
        font-size: 2.2rem;
        margin-bottom: 1.8rem;
        font-weight: bold;
        color: #01BF71;
        text-align: center;
    }
    .generalPrice{
        font-size: 1.8rem;
        margin-top: 1.8rem;
        text-align: center;
    }

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }
    
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

export const PricesTable = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0 auto;
    border: 1px solid black;
    border-radius: 10px;
    padding: 30px;
    background-color: #101522;
    color: white;
    transition: all 0.2s ease-in-out;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    ul{
        li{
            list-style:none;
            padding: 0.4rem 0;
        }
    }
`;
