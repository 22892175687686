import React from 'react';
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServiceElements';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BuildIcon from '@material-ui/icons/Build';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';

const Services = () => {
    return (
        <ServicesContainer id='services'>
            <ServicesH1>Szolgáltatásaink</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon><BuildIcon/></ServicesIcon>
                    <ServicesH2>Szervizelés</ServicesH2>
                    <ServicesP>Kis és nagygenerál, egyéb szervizek elvégzése</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon><DirectionsBikeIcon/></ServicesIcon>
                    <ServicesH2>Kerékpárok</ServicesH2>
                    <ServicesP>Jelenleg Csepel kerékpárok kaphatók széles választékban</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon><HelpOutlineIcon/></ServicesIcon>
                    <ServicesH2>Alkatrészek</ServicesH2>
                    <ServicesP>Shimano, Csepel alkatrészek és kiegészítőket is forgalmazunk</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services;
